import { CalendarTodayRounded, LocationOnRounded, PersonSearchRounded } from "@mui/icons-material";
import { twJoin } from "tailwind-merge";
import { MatchPill } from "./AdverseMediaV2";
import { Tooltip, styled, TooltipProps } from "@mui/material";

const MatchExplanationTooltip = styled(({ className, ...props }: { className?: string } & TooltipProps) => (
  <Tooltip {...props} title={props.title} componentsProps={{ tooltip: { className } }}>
    {props.children}
  </Tooltip>
))(`    
    background-color: rgba(15, 23, 42, 0.8);   
    padding: 8px 16px;

    & .MuiTooltip-arrow {
      color: rgba(15, 23, 42, 0.8);
    }
`);

const BusinessPerpetratorTable = ({
  mediaHit,
  input,
  hitTableLabel,
  greenColor = false,
}: {
  mediaHit: any;
  input: any;
  hitTableLabel: string;
  greenColor?: boolean;
}) => {
  const mediaHitObj = mediaHit.profile_review || mediaHit;
  const businessNameMatch = mediaHitObj.business_name_match;
  const bestAddressMatch = mediaHitObj.best_address_match;

  const formatInputAddress = (address: any) => {
    if (!address) return "";
    return `${address.street_1 ?? ""} ${address.street_2 ?? ""} ${address.city ?? ""} ${address.state ?? ""} ${
      address.postal_code ?? ""
    } ${address.country_code ?? ""}`.trim();
  };

  const inputAddressOfIncorporation = formatInputAddress(input.address_of_incorporation);
  const inputAddressOfOperation = formatInputAddress(input.address_of_operation);

  const associatedAddresses = mediaHit.associated_addresses;
  const associatedCountries = mediaHit.associated_countries;

  const formatMatchedAddresses = (addresses: any[], countries: any[]) => {
    const allLocations = [];
    if (addresses?.length > 0) {
      allLocations.push(
        ...addresses
          .map((addr: any) => {
            // Handle the case where all fields might be null except country_code
            if (!addr.city && !addr.state && !addr.country?.country_name && addr.country_code) {
              return addr.country_code;
            }
            return [addr.city, addr.state, addr.country?.country_name].filter(Boolean).join(", ");
          })
          .filter((addr: string) => addr),
      );
    }
    if (countries?.length > 0) {
      allLocations.push(...countries.map((country: any) => country.country_name).filter(Boolean));
    }
    return allLocations.length > 0 ? allLocations.join("; ") : "";
  };

  // Format associated addresses for display
  const associatedAddressesString =
    associatedAddresses?.map((address: any) => formatInputAddress(address)).join("; ") || "Not Found";

  // Format locations based on availability - only use countries if no addresses exist
  const allAssociatedLocationsString =
    associatedAddresses?.length > 0
      ? formatMatchedAddresses(associatedAddresses, []) // Only show addresses if they exist
      : formatMatchedAddresses([], associatedCountries || []); // Only show countries if no addresses exist

  let perpetratorName = businessNameMatch.display_name || businessNameMatch.name;
  if (perpetratorName && perpetratorName !== mediaHit.business_name) {
    perpetratorName = `${perpetratorName} (Alias)`;
  }

  return (
    <table className="w-0 min-w-full table-auto leading-normal text-xs border-b border-slate-300">
      <thead>
        <tr className="text-left bg-slate-50">
          <th className="py-2 px-1.5 pl-5"></th>
          <th className="py-2 px-1.5 text-slate-500">Customer Information</th>
          <th className="py-2 px-1.5">{hitTableLabel}</th>
          <th className="py-2 px-1.5"></th>
        </tr>
      </thead>
      <tbody className="w-full border-separate border-spacing-2 divide-y divide-slate-200">
        <tr className="text-left">
          <td className="whitespace-nowrap px-1.5 py-2 pl-5">
            <div className="flex gap-x-2 items-center">
              <PersonSearchRounded sx={{ fontSize: "1rem" }} />
              <span>Business Name</span>
            </div>
          </td>
          <td className="text-slate-900 truncate px-1.5 py-2">
            <span>{input.business_name}</span>
          </td>
          <td
            className={twJoin(
              "truncate px-1.5 py-2",
              businessNameMatch?.exact_match
                ? "text-red-700 font-semibold"
                : businessNameMatch?.partial_match
                  ? "text-orange-600"
                  : businessNameMatch?.mismatch
                    ? "text-yellow-800"
                    : "text-slate-600 italic",
            )}
          >
            <span className={twJoin(businessNameMatch?.exact_match ? "font-semibold" : "")}>
              {perpetratorName || mediaHit.business_name}
            </span>
          </td>
          <td className="px-1.5 py-2 text-right">
            <div className="inline-flex">
              <>
                {businessNameMatch?.exact_match ? (
                  <MatchExplanationTooltip
                    className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                    title={businessNameMatch.explanation || ""}
                    placement="top"
                    arrow
                  >
                    <div>
                      <MatchPill matchRating="strong_match" />
                    </div>
                  </MatchExplanationTooltip>
                ) : businessNameMatch?.partial_match ? (
                  <MatchExplanationTooltip
                    className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                    title={businessNameMatch.explanation || ""}
                    placement="top"
                    arrow
                  >
                    <div>
                      <MatchPill matchRating="partial_match" />
                    </div>
                  </MatchExplanationTooltip>
                ) : businessNameMatch?.mismatch ? (
                  <MatchExplanationTooltip
                    className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                    title={businessNameMatch.explanation || ""}
                    placement="top"
                    arrow
                  >
                    <div>
                      <MatchPill matchRating="mismatch" />
                    </div>
                  </MatchExplanationTooltip>
                ) : (
                  <MatchExplanationTooltip
                    className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                    title="No match information available"
                    placement="top"
                    arrow
                  >
                    <div>
                      <MatchPill matchRating="no_match" />
                    </div>
                  </MatchExplanationTooltip>
                )}
              </>
            </div>
          </td>
        </tr>
        {bestAddressMatch && (
          <tr>
            <td className="whitespace-nowrap px-1.5 py-2 pl-5">
              <div className="flex gap-x-2 items-center">
                <LocationOnRounded sx={{ fontSize: "1rem" }} />
                <span>Address</span>
              </div>
            </td>
            <td className="text-slate-900 truncate px-1.5 py-2">
              <span>{inputAddressOfOperation}</span>
            </td>
            <td
              className={twJoin(
                "text-wrap px-1.5 py-2",
                bestAddressMatch?.exact_match
                  ? "text-red-700 font-semibold"
                  : bestAddressMatch?.partial_match
                    ? "text-orange-600 font-semibold"
                    : bestAddressMatch?.mismatch
                      ? "text-yellow-800"
                      : "text-slate-400 italic",
              )}
            >
              <span>
                {bestAddressMatch?.exact_match || bestAddressMatch?.partial_match
                  ? [
                      bestAddressMatch.matched_street,
                      bestAddressMatch.matched_city,
                      bestAddressMatch.matched_state,
                      bestAddressMatch.matched_country,
                    ]
                      .filter(Boolean)
                      .join(", ") || "Not Found"
                  : allAssociatedLocationsString || "Not Found"}
              </span>
            </td>
            <td className="py-2 px-1.5 text-right">
              <div className="inline-flex">
                <MatchExplanationTooltip
                  className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                  title={bestAddressMatch?.explanation || "No match information available"}
                  placement="top"
                  arrow
                >
                  <div>
                    <MatchPill
                      matchRating={
                        bestAddressMatch?.exact_match
                          ? "strong_match"
                          : bestAddressMatch?.partial_match
                            ? "partial_match"
                            : bestAddressMatch?.mismatch
                              ? "mismatch"
                              : "no_match"
                      }
                    />
                  </div>
                </MatchExplanationTooltip>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default BusinessPerpetratorTable;
