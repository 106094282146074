// @ts-nocheck
import {
  KYC_ADVERSE_MEDIA_CHECK_ID,
  KYC_SOURCE_OF_WEALTH_CHECK_ID,
  KYC_PEP_CHECK_ID,
  shimmerEffectClassNames,
  KYC_REAL_ESTATE_CHECK_ID,
  KYC_OPEN_CORPORATES_BUSINESS_CHECK_ID,
} from "@/constants/vars";
import {
  CakeRounded,
  GppGoodRounded,
  BusinessCenterRounded,
  EmailRounded,
  LocationOnRounded,
  PhoneRounded,
  PersonRounded,
  HomeWorkRounded,
  BusinessRounded,
  CheckCircleRounded,
  SearchOffRounded,
} from "@mui/icons-material";
import AdverseMediaV3 from "./AdverseMediaV3";
import SourceOfWealthV2 from "../SourceOfWealthV2";
import { format } from "date-fns";
import { twJoin } from "tailwind-merge";
import { calculateAge, isAddressProvided } from "@/utils";
import CheckSectionStatusMessage from "../V2_Home_Page/PublicBDDComponents/sections/CheckSectionStatusMessage";
import PEPCardV3 from "./PEPCardV3";
import RealEstateCard from "./RealEstateCard";
import SourceOfWealthOpenCorporatesOfficersCard from "./SourceOfWealthOpenCorporatesOfficersCard";
import IDDAlert from "./IDDAlert";

const NoMatchesFound = () => (
  <div className="w-fit flex gap-2 text-slate-700 text-sm items-center bg-slate-50 rounded-lg p-3">
    <CheckCircleRounded sx={{ fontSize: "1rem" }} className="bg-white text-green-700 rounded-full" />
    No strong matches found.
  </div>
);

const IDDReport = ({
  endpointUrl,
  jobMetadata,
  agentKey,
}: {
  endpointUrl: string;
  jobMetadata: any;
  agentKey: string;
}) => {
  const input = jobMetadata.job.input_payload?.self_attested_data;

  const kyc = jobMetadata.kyc;
  const kycCheckResults = Object.values(kyc)[0]?.check_results;

  const adverseMediaCheckResult = kycCheckResults?.find(
    (checkResult) => checkResult.command_id === KYC_ADVERSE_MEDIA_CHECK_ID,
  );

  const openCorporatesCheckResult = kycCheckResults?.find(
    (checkResult) => checkResult.command_id === KYC_OPEN_CORPORATES_BUSINESS_CHECK_ID,
  );

  const openCorporatesStatusMessages = openCorporatesCheckResult?.status_messages?.filter(
    (message) => message.event === "status",
  );
  const lastOpenCorporatesStatusMessage = openCorporatesStatusMessages?.[openCorporatesStatusMessages.length - 1];

  const pepCheckResult = kycCheckResults?.find((checkResult) => checkResult.command_id === KYC_PEP_CHECK_ID);

  const sourceOfWealthCheckResult = kycCheckResults?.find(
    (checkResult) => checkResult.command_id === KYC_SOURCE_OF_WEALTH_CHECK_ID,
  );

  const adverseMediaStatusMessages = adverseMediaCheckResult?.status_messages?.filter(
    (message) => message.event === "status",
  );
  const lastAdverseMediaStatusMessage = adverseMediaStatusMessages?.[adverseMediaStatusMessages.length - 1];

  const pepStatusMessages = pepCheckResult?.status_messages?.filter((message) => message.event === "status");
  const lastPepStatusMessage = pepStatusMessages?.[pepStatusMessages.length - 1];

  const realEstateCheckResult = kycCheckResults?.find(
    (checkResult) => checkResult.command_id === KYC_REAL_ESTATE_CHECK_ID,
  );

  const realEstateStatusMessages = realEstateCheckResult?.status_messages?.filter(
    (message) => message.event === "status",
  );
  const lastRealEstateStatusMessage = realEstateStatusMessages?.[realEstateStatusMessages.length - 1];

  const sourceOfWealthStatusMessages = sourceOfWealthCheckResult?.status_messages?.filter(
    (message) => message.event === "status",
  );
  const lastSourceOfWealthStatusMessage = sourceOfWealthStatusMessages?.[sourceOfWealthStatusMessages.length - 1];

  const filteredProfiles = sourceOfWealthCheckResult?.check_result?.profiles?.filter((profile) =>
    ["strong_match", "partial_match"].includes(profile.profile_review?.match_rating?.match),
  );

  return (
    <div className="w-full">
      {/* Customer Inputs Section */}
      <section className="mb-8">
        <h2 className="text-lg font-semibold mb-4 flex items-center gap-2">
          <PersonRounded sx={{ fontSize: "1.25rem" }} className="text-slate-500" />
          Customer Inputs
        </h2>

        <div className="bg-slate-50 rounded-lg p-5 text-xs text-slate-700 w-full">
          <div className="flex flex-col gap-3 w-full">
            <h3 className="text-xl font-semibold">
              {input?.first_name} {input?.last_name}
            </h3>
            {input?.date_of_birth || input?.address || input?.phone || input?.email ? (
              <div className="flex items-baseline justify-between gap-5">
                {input.date_of_birth && (
                  <div className="flex flex-col gap-1 flex-1">
                    <span className="flex items-center text-slate-600 gap-2">
                      <CakeRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                      <span>{`${calculateAge(input.date_of_birth)}, ${format(
                        new Date(input?.date_of_birth.replaceAll("-", "/")),
                        "MMMM dd, yyyy",
                      )}`}</span>
                    </span>
                  </div>
                )}
                {input?.address &&
                  isAddressProvided({
                    street1: input?.address?.street_1,
                    street2: input?.address?.street_2,
                    city: input?.address?.city,
                    state: input?.address?.state,
                    countryCode: input?.address?.country_code,
                    postalCode: input?.address?.postal_code,
                  }) && (
                    <div className="flex gap-1 text-slate-700 text-xs flex-1">
                      <LocationOnRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                      <div className="flex flex-col gap-1">
                        {input?.address?.street_1 && <span>{input?.address?.street_1}</span>}
                        {input?.address?.street_2 && <span>{input?.address?.street_2}</span>}
                        {input?.address?.city ||
                          input?.address?.state ||
                          input?.address?.country_code ||
                          (input?.address?.postal_code && (
                            <span>
                              {input?.address?.city ? `${input?.address?.city}, ` : ""}
                              {input?.address?.state ? `${input?.address?.state}, ` : ""}
                              {input?.address?.country_code ? `${input?.address?.country_code}, ` : ""}
                              {input?.address?.postal_code ? `${input?.address?.postal_code}` : ""}
                            </span>
                          ))}
                      </div>
                    </div>
                  )}
                {(input.phone || input.email) && (
                  <div className="grid grid-columns-[minmax(16px,1fr)_2fr] gap-2 flex-1">
                    {input.phone && (
                      <div className="flex items-center gap-1">
                        <PhoneRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                        <span className="text-slate-600">{input.phone}</span>
                      </div>
                    )}
                    {input.email && (
                      <div className="flex items-center gap-1">
                        <EmailRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                        <span className="text-slate-600">{input.email}</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </section>

      {/* AML Screenings Section */}
      {adverseMediaCheckResult || pepCheckResult ? (
        <section className="mb-8">
          <h2
            className={twJoin(
              "text-lg font-semibold mb-4 flex items-center gap-2",
              (adverseMediaCheckResult && !["complete", "error"].includes(adverseMediaCheckResult?.status)) ||
                (pepCheckResult && !["complete", "error"].includes(pepCheckResult?.status))
                ? shimmerEffectClassNames
                : "",
            )}
          >
            <GppGoodRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
            AML Screenings
          </h2>
          <div className="space-y-4">
            {/* Show "No strong matches found" when either check is complete with no hits */}
            {(adverseMediaCheckResult &&
              ["complete", "error"].includes(adverseMediaCheckResult?.status) &&
              !adverseMediaCheckResult?.check_result?.verified_adverse_media_hits?.filter(
                (hit) => hit.escalate_for_review,
              )?.length &&
              !pepCheckResult) ||
            (pepCheckResult &&
              ["complete", "error"].includes(pepCheckResult?.status) &&
              !pepCheckResult?.check_result?.verified_pep_hits?.filter((hit) => hit.escalate_for_review)?.length &&
              !adverseMediaCheckResult) ||
            (adverseMediaCheckResult &&
              pepCheckResult &&
              ["complete", "error"].includes(adverseMediaCheckResult?.status) &&
              ["complete", "error"].includes(pepCheckResult?.status) &&
              !adverseMediaCheckResult?.check_result?.verified_adverse_media_hits?.filter(
                (hit) => hit.escalate_for_review,
              )?.length &&
              !pepCheckResult?.check_result?.verified_pep_hits?.filter((hit) => hit.escalate_for_review)?.length) ? (
              <>
                {adverseMediaCheckResult?.passed ? (
                  <IDDAlert level="success" message={adverseMediaCheckResult.answer} type="adverse_media" />
                ) : null}
                {pepCheckResult?.passed ? (
                  <IDDAlert level="success" message={pepCheckResult.answer} type="pep" />
                ) : null}
              </>
            ) : (
              <>
                {adverseMediaCheckResult ? (
                  ["complete", "error"].includes(adverseMediaCheckResult?.status) ? (
                    adverseMediaCheckResult?.check_result?.verified_adverse_media_hits?.filter(
                      (hit) => hit.escalate_for_review,
                    )?.length > 0 ? (
                      <AdverseMediaV3
                        commandId={adverseMediaCheckResult?.command_id}
                        agentInstanceId={adverseMediaCheckResult?.agent_instance_id}
                        agentKey={agentKey}
                        apiKey=""
                        commandInstanceId={adverseMediaCheckResult?.command_instance_id}
                        displayOnlyMatches={true}
                        endpointUrl={endpointUrl}
                        verifiedAdverseMediaHits={
                          adverseMediaCheckResult?.check_result?.verified_adverse_media_hits || []
                        }
                        input={adverseMediaCheckResult.input_data}
                        jobId={jobMetadata.job.id}
                        showAllContent={false}
                        allCollapsed={true}
                        displayType={true}
                        showVendorId={true}
                      />
                    ) : (
                      <IDDAlert level="success" message={adverseMediaCheckResult.answer} type="adverse_media" />
                    )
                  ) : (
                    <>
                      <CheckSectionStatusMessage message={lastAdverseMediaStatusMessage?.content?.status} />
                      <div className="animate-pulse space-y-4">
                        <div className="h-16 bg-slate-200 rounded-lg w-full" />
                        <div className="h-16 bg-slate-200 rounded-lg w-full" />
                        <div className="h-16 bg-slate-200 rounded-lg w-full" />
                      </div>
                    </>
                  )
                ) : null}

                {pepCheckResult ? (
                  ["complete", "error"].includes(pepCheckResult?.status) ? (
                    pepCheckResult?.check_result?.verified_pep_hits?.filter((hit) => hit.escalate_for_review)?.length >
                    0 ? (
                      <PEPCardV3
                        commandId={pepCheckResult?.command_id}
                        agentInstanceId={pepCheckResult?.agent_instance_id}
                        agentKey={agentKey}
                        apiKey=""
                        commandInstanceId={pepCheckResult?.command_instance_id}
                        displayOnlyMatches={true}
                        endpointUrl={endpointUrl}
                        verifiedPepHits={pepCheckResult?.check_result?.verified_pep_hits || []}
                        input={pepCheckResult?.input_data}
                        jobId={jobMetadata.job.id}
                        showAllContent={false}
                        allCollapsed={true}
                        displayType={true}
                        showVendorId={true}
                      />
                    ) : (
                      <IDDAlert level="success" message={pepCheckResult.answer} type="pep" />
                    )
                  ) : (
                    <>
                      <CheckSectionStatusMessage message={lastPepStatusMessage?.content?.status} />
                      <div className="animate-pulse space-y-4">
                        <div className="h-16 bg-slate-200 rounded-lg w-full" />
                        <div className="h-16 bg-slate-200 rounded-lg w-full" />
                        <div className="h-16 bg-slate-200 rounded-lg w-full" />
                      </div>
                    </>
                  )
                ) : null}
              </>
            )}
          </div>
        </section>
      ) : null}

      {/* SOW Section */}
      {sourceOfWealthCheckResult ? (
        <section className="mb-8">
          <h2
            className={twJoin(
              "text-lg font-semibold mb-4 flex items-center gap-2",
              !["complete", "error"].includes(sourceOfWealthCheckResult?.status) ? shimmerEffectClassNames : "",
            )}
          >
            <BusinessCenterRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
            Sources of Income
            {!filteredProfiles?.length && <SearchOffRounded className="h-4 w-4 text-slate-500 ml-auto" />}
          </h2>

          <div className="space-y-4">
            {["complete", "error"].includes(sourceOfWealthCheckResult?.status) ? (
              <SourceOfWealthV2
                commandId={sourceOfWealthCheckResult?.command_id}
                agentInstanceId={sourceOfWealthCheckResult?.agent_instance_id}
                agentKey={agentKey}
                apiKey=""
                commandInstanceId={sourceOfWealthCheckResult?.command_instance_id}
                endpointUrl={endpointUrl}
                profiles={filteredProfiles || []}
                input={sourceOfWealthCheckResult.input_data}
                jobId={jobMetadata.job.id}
                showAllContent={false}
                hideHeader={true}
                allCollapsed={true}
              />
            ) : (
              <div className="space-y-4">
                <CheckSectionStatusMessage message={lastSourceOfWealthStatusMessage?.content?.status} />
                <div className="animate-pulse">
                  <div className="h-24 bg-slate-200 rounded-lg w-full" />
                </div>
              </div>
            )}
          </div>
        </section>
      ) : null}

      {/* Real Estate Section */}
      {realEstateCheckResult ? (
        <section className="mb-8">
          <h2
            className={twJoin(
              "text-lg font-semibold mb-4 flex items-center gap-2",
              !["complete", "error"].includes(realEstateCheckResult?.status) ? shimmerEffectClassNames : "",
            )}
          >
            <HomeWorkRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
            Real Estate
            {["complete", "error"].includes(realEstateCheckResult?.status) &&
              !realEstateCheckResult?.check_result?.profiles?.length && (
                <SearchOffRounded className="h-4 w-4 text-slate-500 ml-auto" />
              )}
          </h2>
          <div className="space-y-4">
            {["complete", "error"].includes(realEstateCheckResult?.status) ? (
              realEstateCheckResult.check_result?.profiles?.length > 0 ? (
                realEstateCheckResult.check_result.profiles.map((profile) => (
                  <RealEstateCard hit={profile} input={realEstateCheckResult.input_data} />
                ))
              ) : (
                <div className="flex flex-col h-full bg-slate-50 p-3 rounded-[8px] w-auto">
                  <div className="flex items-center">
                    <div className="text-sm text-slate-700">
                      Could not find enough information to confidently provide evidence of real estate ownership.
                    </div>
                  </div>
                </div>
              )
            ) : (
              <>
                <CheckSectionStatusMessage message={lastRealEstateStatusMessage?.content?.status} />
                <div className="animate-pulse">
                  <div className="h-24 bg-slate-200 rounded-lg w-full" />
                </div>
              </>
            )}
          </div>
        </section>
      ) : null}

      {/* Open Corporates Section */}
      {openCorporatesCheckResult ? (
        <>
          <section className="mb-8">
            <h2
              className={twJoin(
                "text-lg font-semibold mb-4 flex items-center gap-2",
                !["complete", "error"].includes(openCorporatesCheckResult?.status) ? shimmerEffectClassNames : "",
              )}
            >
              <BusinessRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
              Owned Businesses
              {["complete", "error"].includes(openCorporatesCheckResult?.status) &&
                !openCorporatesCheckResult?.check_result?.profiles?.length && (
                  <SearchOffRounded className="h-4 w-4 text-slate-500 ml-auto" />
                )}
            </h2>

            <div className="space-y-4">
              {["complete", "error"].includes(openCorporatesCheckResult?.status) ? (
                openCorporatesCheckResult.check_result?.profiles?.length > 0 ? (
                  openCorporatesCheckResult.check_result.profiles.map((profile, index) => (
                    <SourceOfWealthOpenCorporatesOfficersCard
                      key={`source-of-wealth-hit-${profile.id}-${index}`}
                      hit={profile}
                      input={openCorporatesCheckResult.input_data}
                    />
                  ))
                ) : (
                  <div className="flex flex-col h-full bg-slate-50 p-3 rounded-[8px] w-auto">
                    <div className="flex items-center">
                      <div className="text-sm text-slate-700">
                        Could not find enough information to confidently provide evidence of owned businesses.
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <>
                  <CheckSectionStatusMessage message={lastOpenCorporatesStatusMessage?.content?.status} />
                  <div className="animate-pulse">
                    <div className="h-24 bg-slate-200 rounded-lg w-full" />
                  </div>
                </>
              )}
            </div>
          </section>
        </>
      ) : null}
    </div>
  );
};

export default IDDReport;
