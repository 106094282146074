import { useParchaApi } from "@/hooks/useParchaApi";
import { Fragment, useRef, useState, ChangeEvent, useEffect, useMemo } from "react";
import { twJoin } from "tailwind-merge";
import {
  UploadFileRounded,
  AutoAwesomeRounded,
  DescriptionRounded,
  DeleteForeverRounded,
  ExpandMoreRounded,
  ErrorRounded,
  ExpandLessRounded,
} from "@mui/icons-material";
import { Menu, Transition } from "@headlessui/react";
import BusinessRegistrationForm from "@/components/DocumentForms/BusinessRegistrationForm";
import ProofOfAddressForm from "@/components/DocumentForms/ProofOfAddressForm";
import EINDocumentForm from "@/components/DocumentForms/EINDocumentForm";
import CannabisLicenseForm from "@/components/DocumentForms/CannabisLicenseForm";
import BusinessOwnershipForm from "@/components/DocumentForms/BusinessOwnershipForm";
import {
  INCORPORATION_DOCUMENT_CHECK_ID,
  PROOF_OF_ADDRESS_CHECK_ID,
  EIN_DOCUMENT_CHECK_ID,
  CANNABIS_LICENSE_CHECK_ID,
  BUSINESS_OWNERSHIP_CHECK_ID,
} from "@/constants/vars";
import { isAddressProvided } from "@/utils";
import { fileToBase64 } from "@/utils";
import { DOCUMENT_TYPES, DOCUMENT_VALIDATION_CHECK_IDS } from "@/constants/vars";
import _ from "lodash";
import { Endpoint } from "@/types";

const DocumentVerificationView = ({
  endpoint,
  onRunFileCheckHandler,
}: {
  endpoint: Endpoint;
  onRunFileCheckHandler: (checkRunResponse: { id: string }) => void;
}) => {
  const [showForm, setShowForm] = useState<boolean>(true);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [selectedDocumentType, setSelectedDocumentType] = useState<string>("");

  const [error, setError] = useState<string | null>(null);

  const [enableExtractionOnly, setEnableExtractionOnly] = useState<boolean>(false);
  const [isVisualVerificationVisible, setIsVisualVerificationVisible] = useState<boolean>(false);
  const [isFraudCheckVisible, setIsFraudCheckVisible] = useState<boolean>(false);

  const [enableVisualVerification, setEnableVisualVerification] = useState<boolean>(false);
  const [enableFraudCheck, setEnableFraudCheck] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const parchaApi = useParchaApi();

  const allowedCheckIds = useMemo(
    () =>
      _.intersection(DOCUMENT_VALIDATION_CHECK_IDS, endpoint?.agentConfig?.steps?.map((step) => step.command_id) || []),
    [endpoint],
  );

  useEffect(() => {
    const selectedAgentStep = endpoint?.agentConfig?.steps?.find((step) => step.command_id === selectedDocumentType);

    const toolArgs = selectedAgentStep?.tool_args;

    // Control the visual verification and fraud check
    if (selectedDocumentType === INCORPORATION_DOCUMENT_CHECK_ID) {
      setEnableVisualVerification(false);
      setIsVisualVerificationVisible(true);
      setEnableFraudCheck(false);
      setIsFraudCheckVisible(true);
    } else {
      setEnableVisualVerification(false);
      setEnableFraudCheck(false);
      setIsVisualVerificationVisible(false);
      setIsFraudCheckVisible(false);
    }

    // Control the form and extraction only mode
    if (selectedDocumentType === INCORPORATION_DOCUMENT_CHECK_ID) {
      setEnableExtractionOnly(true);
      setShowForm(false);
    } else if (toolArgs) {
      setEnableExtractionOnly(Boolean(toolArgs.check_self_attested_ownership));
      setShowForm(!Boolean(toolArgs.check_self_attested_ownership));
    } else {
      setEnableExtractionOnly(false);
      setShowForm(true);
    }
  }, [selectedDocumentType, endpoint]);

  useEffect(() => {
    if (!endpoint) return;

    const availableCheckIds = _.intersection(
      DOCUMENT_VALIDATION_CHECK_IDS,
      endpoint?.agentConfig?.steps?.map((step) => step.command_id) || [],
    );

    if (!availableCheckIds.includes(selectedDocumentType)) {
      setSelectedDocumentType(availableCheckIds[0] || "");
    }
  }, [endpoint]);

  const [formData, setFormData] = useState({
    business_name: "",
    cannabis_license_number: "",
    description: "",
    registered_business_name: "",
    business_registration_number: "",
    tin_number: "",
    incorporation_date: "",
    address_of_incorporation: {
      street_1: "",
      street_2: "",
      city: "",
      state: "",
      country_code: "",
      postal_code: "",
    },
    address_of_operation: {
      street_1: "",
      street_2: "",
      city: "",
      state: "",
      country_code: "",
      postal_code: "",
    },
    validity_period: 90,
    associated_individuals: [
      {
        first_name: "",
        middle_name: "",
        last_name: "",
        business_ownership_percentage: "",
      },
    ],
  });

  const processFile = async (file: File) => {
    setSelectedFile(file);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const files = e.dataTransfer.files;

    if (!files || !files.length) return;
    processFile(files[0]);
  };

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || !files.length) return;
    processFile(files[0]);
  };

  const handleDocumentInputClick = () => {
    inputRef.current?.click();
  };

  const runFileCheck = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!selectedFile || !endpoint) return;

    setIsSubmitting(true);
    // Check for required fields
    if ([EIN_DOCUMENT_CHECK_ID].includes(selectedDocumentType) && !formData.registered_business_name) {
      setFormErrors(["Registered Business Name is required"]);
      return;
    } else if (selectedDocumentType === PROOF_OF_ADDRESS_CHECK_ID && !formData.business_name) {
      setFormErrors(["Business Name is required"]);
      return;
    } else if (
      selectedDocumentType === CANNABIS_LICENSE_CHECK_ID &&
      !formData.business_name &&
      !formData.cannabis_license_number
    ) {
      setFormErrors(["Business Name and License Number are required"]);
      return;
    }

    setFormErrors([]);
    setError(null);

    // Convert file to base64
    const base64String = await fileToBase64(selectedFile);
    try {
      const checkRunResponse = await parchaApi.runCheck(endpoint.endpointUrl, selectedDocumentType, {
        agent_key: endpoint.agentKey,
        kyb_schema: {
          id: crypto.randomUUID(),
          self_attested_data: {
            cannabis_license_number: formData.cannabis_license_number?.length
              ? formData.cannabis_license_number
              : undefined,
            description: formData.description?.length ? formData.description : undefined,
            business_name: formData.business_name?.length ? formData.business_name : undefined,
            registered_business_name: formData.registered_business_name?.length
              ? formData.registered_business_name
              : undefined,
            business_registration_number: formData.business_registration_number?.length
              ? formData.business_registration_number
              : undefined,
            business_ownership_documents:
              selectedDocumentType === BUSINESS_OWNERSHIP_CHECK_ID
                ? [{ file_name: selectedFile.name, b64_document: base64String }]
                : undefined,
            cannabis_license_documents:
              selectedDocumentType === CANNABIS_LICENSE_CHECK_ID
                ? [{ file_name: selectedFile.name, b64_document: base64String }]
                : undefined,
            proof_of_address_documents:
              selectedDocumentType === PROOF_OF_ADDRESS_CHECK_ID
                ? [{ file_name: selectedFile.name, b64_document: base64String }]
                : undefined,
            incorporation_documents:
              selectedDocumentType === INCORPORATION_DOCUMENT_CHECK_ID
                ? [{ file_name: selectedFile.name, b64_document: base64String }]
                : undefined,
            ein_documents:
              selectedDocumentType === EIN_DOCUMENT_CHECK_ID
                ? [{ file_name: selectedFile.name, b64_document: base64String }]
                : undefined,
            address_of_incorporation: isAddressProvided({
              street1: formData.address_of_incorporation.street_1,
              street2: formData.address_of_incorporation.street_2,
              city: formData.address_of_incorporation.city,
              state: formData.address_of_incorporation.state,
              countryCode: formData.address_of_incorporation.country_code,
              postalCode: formData.address_of_incorporation.postal_code,
            })
              ? formData.address_of_incorporation
              : undefined,
            address_of_operation: isAddressProvided({
              street1: formData.address_of_operation.street_1,
              street2: formData.address_of_operation.street_2,
              city: formData.address_of_operation.city,
              state: formData.address_of_operation.state,
              countryCode: formData.address_of_operation.country_code,
              postalCode: formData.address_of_operation.postal_code,
            })
              ? formData.address_of_operation
              : undefined,

            incorporation_date: formData.incorporation_date?.length ? formData.incorporation_date : undefined,
            tin_number: formData.tin_number?.length ? formData.tin_number : undefined,
          },
          associated_individuals: formData.associated_individuals.some(
            (individual) => individual.first_name && individual.last_name && individual.business_ownership_percentage,
          )
            ? formData.associated_individuals.map((individual) => ({
                id: `individual-${individual.first_name}-${individual.last_name}`,
                self_attested_data: {
                  first_name: individual.first_name,
                  middle_name: individual.middle_name,
                  last_name: individual.last_name,
                  business_ownership_percentage: individual.business_ownership_percentage,
                },
              }))
            : undefined,
        },
        check_args: {
          enable_visual_verification:
            selectedDocumentType === INCORPORATION_DOCUMENT_CHECK_ID ? enableVisualVerification : undefined,
          enable_fraud_check: enableFraudCheck,
          verify_visual_verification_passed:
            selectedDocumentType === INCORPORATION_DOCUMENT_CHECK_ID ? enableVisualVerification : undefined,
          verify_no_high_risk_fraud:
            selectedDocumentType === INCORPORATION_DOCUMENT_CHECK_ID ? enableVisualVerification : undefined,
          validity_period: selectedDocumentType === PROOF_OF_ADDRESS_CHECK_ID ? formData.validity_period : undefined,
          verify_business_name:
            selectedDocumentType === INCORPORATION_DOCUMENT_CHECK_ID
              ? formData.registered_business_name?.length > 0
              : undefined,
          verify_address:
            selectedDocumentType === INCORPORATION_DOCUMENT_CHECK_ID
              ? isAddressProvided({
                  street1: formData.address_of_incorporation.street_1,
                  street2: formData.address_of_incorporation.street_2,
                  city: formData.address_of_incorporation.city,
                  state: formData.address_of_incorporation.state,
                  countryCode: formData.address_of_incorporation.country_code,
                  postalCode: formData.address_of_incorporation.postal_code,
                })
              : undefined,
          verify_incorporation_date:
            selectedDocumentType === INCORPORATION_DOCUMENT_CHECK_ID
              ? formData.incorporation_date?.length > 0
              : undefined,
          verify_registration_number:
            selectedDocumentType === INCORPORATION_DOCUMENT_CHECK_ID
              ? formData.business_registration_number?.length > 0
              : undefined,
        },
        tool_args: {
          check_self_attested_ownership:
            selectedDocumentType === BUSINESS_OWNERSHIP_CHECK_ID
              ? Boolean(formData.registered_business_name) && hasRequiredOwnershipInfo(formData)
              : undefined,
        },
      });

      resetFormData();
      onRunFileCheckHandler(checkRunResponse);
    } catch (error) {
      setError("An error has occured while running this check. Please try again.");
    }
  };

  const hasRequiredOwnershipInfo = (formData: any) => {
    return (
      formData.registered_business_name?.length > 0 &&
      formData.associated_individuals.some(
        (individual: { first_name: string; last_name: string; business_ownership_percentage: string }) =>
          individual.first_name && individual.last_name && individual.business_ownership_percentage,
      )
    );
  };

  const resetFormData = () => {
    setFormData({
      business_name: "",
      cannabis_license_number: "",
      description: "",
      registered_business_name: "",
      business_registration_number: "",
      tin_number: "",
      incorporation_date: "",
      address_of_incorporation: {
        street_1: "",
        street_2: "",
        city: "",
        state: "",
        country_code: "",
        postal_code: "",
      },
      address_of_operation: {
        street_1: "",
        street_2: "",
        city: "",
        state: "",
        country_code: "",
        postal_code: "",
      },
      validity_period: 90,
      associated_individuals: [
        {
          first_name: "",
          middle_name: "",
          last_name: "",
          business_ownership_percentage: "",
        },
      ],
    });
    setFormErrors([]);
    setError(null);
  };

  const renderInfoForm = () => {
    switch (selectedDocumentType) {
      case INCORPORATION_DOCUMENT_CHECK_ID:
        return <BusinessRegistrationForm formData={formData} setFormData={setFormData} formErrors={formErrors} />;
      case PROOF_OF_ADDRESS_CHECK_ID:
        return <ProofOfAddressForm formData={formData} setFormData={setFormData} formErrors={formErrors} />;
      case EIN_DOCUMENT_CHECK_ID:
        return <EINDocumentForm formData={formData} setFormData={setFormData} formErrors={formErrors} />;
      case CANNABIS_LICENSE_CHECK_ID:
        return <CannabisLicenseForm formData={formData} setFormData={setFormData} formErrors={formErrors} />;
      case BUSINESS_OWNERSHIP_CHECK_ID:
        return <BusinessOwnershipForm formData={formData} setFormData={setFormData} formErrors={formErrors} />;
      default:
        return null;
    }
  };

  return (
    <>
      {endpoint && (
        <div className="mx-auto max-w-4xl w-full py-10 px-20 overflow-y-auto">
          {error && (
            <div className="flex items-center gap-2 bg-red-50 text-sm text-red-700 p-4 rounded-md">
              <ErrorRounded className="text-red-600" sx={{ fontSize: "1rem" }} />
              {error}
            </div>
          )}
          <div className="px-4 flex-1 flex flex-col gap-y-10 mt-10">
            <div>
              <label htmlFor="document-type" className="block text-sm font-semibold text-slate-700">
                Select document type to verify
              </label>
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="flex w-56 gap-x-1.5 rounded-md bg-white p-2 text-xs md:text-md text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50">
                    <span className="flex-1 line-clamp-1 text-left">
                      {DOCUMENT_TYPES[selectedDocumentType as keyof typeof DOCUMENT_TYPES]}
                    </span>
                    <ExpandMoreRounded
                      sx={{ fontSize: "1rem" }}
                      className="-mr-1 h-4 w-4 text-slate-400"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="h-fit w-full max-h-[70vh] overflow-y-auto absolute left-0 z-50 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {allowedCheckIds.map((checkId) => {
                        return (
                          <Menu.Item key={DOCUMENT_TYPES[checkId as keyof typeof DOCUMENT_TYPES]}>
                            <button
                              onClick={() => setSelectedDocumentType(checkId)}
                              className={twJoin(
                                selectedDocumentType === checkId ? "bg-slate-100 text-slate-900" : "text-slate-700",
                                "block px-4 py-2 text-xs w-full text-left hover:bg-slate-100 hover:text-slate-900 disabled:cursor-not-allowed disabled:bg-opacity-50 disabled:text-slate-500",
                              )}
                            >
                              {DOCUMENT_TYPES[checkId as keyof typeof DOCUMENT_TYPES]}
                            </button>
                          </Menu.Item>
                        );
                      })}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="flex flex-col gap-y-3">
              <div className="flex items-center gap-x-2">
                {enableExtractionOnly ? (
                  <button
                    onClick={() => setShowForm(!showForm)}
                    className="inline-flex items-center gap-x-1 text-slate-500 text-sm font-semibold group hover:text-brand-purple"
                  >
                    {showForm ? (
                      <ExpandLessRounded sx={{ fontSize: "1.25rem" }} className="w-5 h-5" />
                    ) : (
                      <ExpandMoreRounded sx={{ fontSize: "1.25rem" }} className="w-5 h-5" />
                    )}
                    <span className="text-slate-900 group-hover:text-brand-purple">
                      Provide business information to validate in the document(s)
                    </span>
                  </button>
                ) : (
                  <label htmlFor="provide-business-info" className="text-sm font-semibold text-slate-900">
                    Provide business information to validate in the document(s)
                  </label>
                )}
              </div>
              {showForm ? renderInfoForm() : null}
            </div>
            <div className="flex flex-col gap-y-2">
              <label className="block text-sm font-semibold text-slate-900">
                Upload the document(s) you want to verify
              </label>
              {selectedFile ? (
                <div className="flex items-center gap-2 h-full text-sm border py-6 px-3 rounded-xl border-slate-300 text-brand-purple overflow-hidden">
                  <DescriptionRounded sx={{ fontSize: "1.25rem" }} />
                  <span className="line-clamp-1 w-fit">{selectedFile.name}</span>
                  <button onClick={() => setSelectedFile(null)} aria-label="Remove file">
                    <DeleteForeverRounded sx={{ fontSize: "1.25rem" }} className="text-red-600" />
                  </button>
                </div>
              ) : (
                <div
                  className={twJoin(
                    "flex items-center gap-2 justify-center h-full text-sm border-2 border-dashed py-6 px-3 rounded-xl",
                    isDraggingOver ? "border-blue-500 bg-blue-50" : "border-slate-300",
                  )}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  onDragExit={() => setIsDraggingOver(false)}
                  onDragLeave={() => setIsDraggingOver(false)}
                >
                  <UploadFileRounded sx={{ fontSize: "1.25rem" }} className="text-slate-400" />
                  <p className="text-slate-700">
                    Drag & Drop or{" "}
                    <button className="underline text-brand-purple" onClick={handleDocumentInputClick}>
                      Choose a document
                    </button>{" "}
                    to upload
                  </p>
                  <input
                    type="file"
                    name={selectedDocumentType}
                    accept="image/jpg,image/jpeg,image/png,application/pdf"
                    onChange={handleFileSelect}
                    onBlur={(e) => e.preventDefault()}
                    ref={inputRef}
                    className="hidden"
                  />
                </div>
              )}
            </div>
            {(isVisualVerificationVisible || isFraudCheckVisible) && (
              <div>
                <p className="block text-sm font-semibold text-slate-900">
                  Enhance your verification by selecting below options
                </p>
                <div className="mt-2 space-y-2">
                  {isVisualVerificationVisible && (
                    <div className="flex items-center">
                      <input
                        id="vision-model"
                        name="vision-model"
                        type="checkbox"
                        checked={enableVisualVerification}
                        onChange={() => setEnableVisualVerification(!enableVisualVerification)}
                        className="h-4 w-4 rounded border-2 border-slate-400 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label htmlFor="vision-model" className="ml-2 block text-sm text-slate-900">
                        Visual verification
                      </label>
                    </div>
                  )}
                  {isFraudCheckVisible && (
                    <div className="flex items-center">
                      <input
                        id="anti-tampering"
                        name="anti-tampering"
                        type="checkbox"
                        checked={enableFraudCheck}
                        onChange={() => setEnableFraudCheck(!enableFraudCheck)}
                        className="h-4 w-4 rounded border-2 border-slate-400 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label htmlFor="anti-tampering" className="ml-2 block text-sm text-slate-900">
                        Anti-tampering and fraud detection
                      </label>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="justify-self-end sm:mt-10 sm:flex sm:flex-row-reverse px-4">
            <button
              type="button"
              className="inline-flex gap-x-1 items-center w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto disabled:cursor-not-allowed disabled:bg-opacity-50"
              onClick={runFileCheck}
              disabled={
                !selectedFile ||
                formErrors.length > 0 ||
                ([EIN_DOCUMENT_CHECK_ID].includes(selectedDocumentType) && !formData.registered_business_name) ||
                (selectedDocumentType === PROOF_OF_ADDRESS_CHECK_ID && !formData.business_name) ||
                (selectedDocumentType === CANNABIS_LICENSE_CHECK_ID &&
                  !formData.business_name &&
                  !formData.cannabis_license_number) ||
                isSubmitting
              }
            >
              <AutoAwesomeRounded sx={{ fontSize: "1.25rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
              {isSubmitting ? "Verifying..." : "Verify Document"}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentVerificationView;
