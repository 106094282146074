import { AutoAwesomeRounded } from "@mui/icons-material";

export const AutoAwesomeContent = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex gap-2">
      <div className="flex flex-col gap-1">
        <AutoAwesomeRounded sx={{ fontSize: "1rem" }} className="text-brand-purple rounded-full bg-white" />
        <div className="border-l-2 border-brand-purple grow flex-1 ml-2"></div>
      </div>
      {children}
    </div>
  );
};
